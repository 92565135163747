import { z } from 'zod';
import { captureException } from '@sentry/vue';
import { getCookieValue } from 'vue-tools/src/utils/cookies.ts';

/**
 * This file handles the integration with the Didomi Consent Management Platform (CMP),
 * enabling the retrieval, parsing, and formatting of user consent data to append
 * it as a query parameter to URLs. It also includes error handling for scenarios
 * where the Didomi library is unavailable or returns invalid data.
 *
 * Key functionalities:
 * - Validate and structure consent data using Zod schemas.
 * - Extract consent data from the URL hash fragment.
 * - Retrieve current consent status from the Didomi library when available.
 * - Format consent data into a JSON structure and append it to the redirect URL.
 * - Log exceptions via Sentry when errors occur.
 *
 * For more details, refer to:
 * - Didomi API documentation: https://developers.didomi.io/cmp/web-sdk/pass-choices-in-query-string
 * - getCurrentUserStatus API: https://developers.didomi.io/cmp/web-sdk/reference/api#getcurrentuserstatus
 */

const itemSchema = z.object({
    id: z.string(),
    enabled: z.boolean(),
});

const consentDataSchema = z.object({
    vendors: z.record(itemSchema),
    purposes: z.record(itemSchema),
});

type ConsentData = z.infer<typeof consentDataSchema>;
type DecodedBearer = {
    created?: string;
    updated?: string;
    user_id?: string;
    vendors?: { disabled?: string[]; enabled?: string[] };
    vendors_li?: { disabled?: string[]; enabled?: string[] };
    version?: number;
};

declare const window: Window & {
    Didomi?: {
        getCurrentUserStatus?: () => ConsentData;
    };
};

const DIDOMI_PARAM_KEY = 'didomiConfig.user.externalConsent.value';

/**
 * Builds a URL with the given consent data appended as a query parameter.
 */
function buildUrl({ currentUrlToRedirect, externalConsentValue }: { currentUrlToRedirect: string; externalConsentValue: string }): string {
    const url = new URL(currentUrlToRedirect);
    url.searchParams.set(DIDOMI_PARAM_KEY, externalConsentValue);
    return url.toString();
}

/**
 * Extracts a query parameter value from the URL hash fragment.
 */
function getHashParamValue(): string | null {
    const hash = window.location.hash || window.location.search;
    const queryString = hash.includes('?') ? hash.split('?')[1] : '';
    return new URLSearchParams(queryString).get(DIDOMI_PARAM_KEY);
}

/**
 * Retrieves and formats the Didomi consent data, then builds the redirect URL.
 *
 * @param {string} currentUrlToRedirect - The URL to redirect to.
 * @returns {string} The URL with the consent data appended as a query parameter, or the original URL if consent data is unavailable.
 */
export function buildUrlWithConsent({ currentUrlToRedirect }: { currentUrlToRedirect: string }): string {

    // Convert relative URLs to absolute URLs.
    if (currentUrlToRedirect.startsWith('/')) {
        currentUrlToRedirect = new URL(currentUrlToRedirect, window.location.origin).href;
    }

    // Check if the consent data is provided in the URL hash fragment.
    const externalConsentValue = getHashParamValue();
    if (externalConsentValue) {
        return buildUrl({ currentUrlToRedirect, externalConsentValue });
    }

    // Check if the Didomi library is loaded.
    if (typeof window.Didomi?.getCurrentUserStatus !== 'function') {
        return currentUrlToRedirect;
    }

    try {
        // https://developers.didomi.io/cmp/web-sdk/reference/api#getcurrentuserstatus
        const status = consentDataSchema.parse(window.Didomi.getCurrentUserStatus());
        const purposeEnabled = Object.entries(status.purposes)
            .filter(([, purpose]) => purpose.enabled)
            .map(([key]) => key);

        let decodedDidomiJwt = null;
        try {
            decodedDidomiJwt = JSON.parse(atob(getCookieValue('didomi_token') ?? '')) as DecodedBearer;
        } catch {
            /* empty */
        }

        const [vendorsDisabled, vendorsLiDisabled, vendorsEnabled, vendorsLiEnabled] = decodedDidomiJwt
            ? [decodedDidomiJwt.vendors?.disabled ?? [], decodedDidomiJwt.vendors_li?.disabled ?? [], decodedDidomiJwt.vendors?.enabled ?? [], decodedDidomiJwt.vendors_li?.enabled ?? []]
            : [[], [], [], []];

        const consentConfig = {
            purposes: {
                consent: {
                    enabled: purposeEnabled,
                    disabled: [],
                },
                legitimate_interest: {
                    enabled: purposeEnabled,
                    disabled: [],
                },
            },
            vendors: {
                consent: {
                    enabled: vendorsEnabled,
                    disabled: vendorsDisabled,
                },
                legitimate_interest: {
                    enabled: vendorsLiEnabled,
                    disabled: vendorsLiDisabled,
                },
            },
        };
        return buildUrl({ currentUrlToRedirect, externalConsentValue: JSON.stringify(consentConfig) });
    } catch (error) {
        captureException(error);
        return currentUrlToRedirect;
    }
}
