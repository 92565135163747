import { endpoint } from '@/configs/env.ts';

export const getContentSrc = (src: string) => {
    if (src.includes('/s/public') && !src.includes('graphql')) {
        return endpoint + src;
    } else if ((window.location.origin.includes('www-preprod') || window.location.origin.includes('localhost')) && src.includes('/var/local/medias/medium/data/dating/octopus')) {
        return `${src.replace('/var/local/medias/medium/data/dating/octopus', 'https://www.rsforcoach.com/graphql/s')}`;
    }
    return src;
};

export const removeParamFromUrl = (param: string, url = window.location.href, replaceState = true, attempt = 0, maxAttempts = 10): string => {
    const urlObject = new URL(url);
    urlObject.searchParams.delete(param);

    if (replaceState) {
        history.replaceState(history.state, '', urlObject.toString());
    }

    // Vérifie si le param est toujours présent dans la query du navigateur
    // et relance la fonction récursivement si nécessaire
    if (window.location.search.includes(param) && attempt < maxAttempts) {
        setTimeout(() => {
            removeParamFromUrl(param, window.location.href, replaceState, attempt + 1, maxAttempts);
        }, 500);
    }

    return urlObject.toString();
};

export const addParamToUrl = (param: string, value: string | null, url = window.location.href, replaceState = true) => {
    if (!value) {
        return url;
    }
    const urlObject = new URL(url);
    urlObject.searchParams.set(param, value);

    if (replaceState) {
        history.replaceState(history.state, '', urlObject.toString());
    }

    return urlObject.toString();
};

export const getElementBoundingRect = (elementOrSelector: HTMLElement | string, withMargin: boolean = false): DOMRect | null => {
    const element = typeof elementOrSelector === 'string' ? document.querySelector(elementOrSelector) : elementOrSelector;

    if (!element) {
        return null;
    }

    const rect = element.getBoundingClientRect();

    if (!withMargin) {
        return rect;
    }

    const style = window.getComputedStyle(element);
    const marginTop = Number.parseFloat(style.marginTop);
    const marginRight = Number.parseFloat(style.marginRight);
    const marginBottom = Number.parseFloat(style.marginBottom);
    const marginLeft = Number.parseFloat(style.marginLeft);

    return {
        ...rect,
        top: rect.top - marginTop,
        right: rect.right + marginRight,
        bottom: rect.bottom + marginBottom,
        left: rect.left - marginLeft,
        width: rect.width + marginLeft + marginRight,
        height: rect.height + marginTop + marginBottom,
    };
};

export const checkMediaExists = (url: string, mediatype: 'thumbnail' | 'video' | 'audio'): Promise<boolean> =>
    new Promise((resolve) => {
        let mediaElement = null;
        if (mediatype === 'thumbnail') {
            mediaElement = new Image();
            mediaElement.addEventListener('load', () => {
                resolve(true);
            });
            mediaElement.addEventListener('error', () => {
                resolve(false);
            });
        } else if (mediatype === 'video') {
            mediaElement = document.createElement('video');
            mediaElement.addEventListener('loadeddata', () => {
                resolve(true);
            });
            mediaElement.addEventListener('error', () => {
                resolve(false);
            });
        } else {
            mediaElement = document.createElement('audio');
            mediaElement.addEventListener('loadeddata', () => {
                resolve(true);
            });
            mediaElement.addEventListener('error', () => {
                resolve(false);
            });
        }

        (mediaElement as HTMLImageElement | HTMLVideoElement | HTMLAudioElement).src = url;
    });
