import { computed, reactive } from 'vue';
import { SiteDocument, SiteQuery } from '@/generated/gql.coaching.ts';
import { keyClientID, keyOrigin } from '@/states/_globals.ts';
import { useUserStore } from '@/stores/user.ts';
import { getDataDeepCopy, handleError, resetStore, StoreData, waitTime } from '@/utils/pinia.ts';
import { defineStore, storeToRefs } from 'pinia';
import { getContentSrc, removeParamFromUrl } from '@/utils/utils.ts';
import { getApolloClient } from '@/apollo.ts';
import { useNicheStore } from '@/stores/niche.ts';
import { handleEventGtag, handleScriptGtm } from '@/utils/gtm.ts';

import { marked } from 'marked';
import DOMPurify from 'dompurify';

export const useSiteStore = defineStore('site', () => {
    const userStore = storeToRefs(useUserStore());
    const nicheStore = storeToRefs(useNicheStore());

    const data = reactive({
        site: {
            loading: false,
            content: null,
        } as StoreData<SiteQuery['Coaching']['site']>,
        languages: {
            loading: false,
            content: [],
        } as StoreData<SiteQuery['Coaching']['languages']>,
        termsOfService: {
            loading: false,
            content: '',
        } as StoreData<string>,
        privacyPolicy: {
            loading: false,
            content: '',
        } as StoreData<string>,
        legalNotices: {
            loading: false,
            content: '',
        } as StoreData<string>,
        nicheMedias: {
            loading: false,
            content: null,
        } as StoreData<{
            internalName: string;
            img: string;
            title: string;
            catchLine: string;
        }>,
    });
    const originalData = getDataDeepCopy(data);

    return {
        data,

        site: computed(() => {
            // ? Use the principe of 'computed', when a variable in the content is updated, the value of computed is re-calculated
            if (
                !data.site.loading &&
                data.site.content === null &&
                !window.location.pathname.includes('global') &&
                !window.location.pathname.includes('x/location') &&
                !window.location.pathname.includes('x/internal')
            ) {
                // If the user state has changed, we need to fetch the site again (this computed is re-calculated)

                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,no-constant-condition,no-constant-binary-expression
                if (true || userStore.user.value) {
                    void fetchSite(data.site, data.languages);
                }
            }
            return data.site.content;
        }),
        languages: computed(() => data.languages.content),
        niche: computed(() => data.site.content?.niche ?? null),
        lightThumbnail: computed(() => getContentSrc(data.site.content?.thumbnailLight ?? '')),
        darkThumbnail: computed(() => getContentSrc(data.site.content?.thumbnailDark ?? '')),
        termsOfService: computed(() => {
            if (!data.site.loading && !data.termsOfService.loading && data.termsOfService.content === '') {
                void fetchTerms(data.termsOfService, (data.site.content?.termsOfService as string | undefined) ?? '');
            }
            return data.termsOfService.content;
        }),
        privacyPolicy: computed(() => {
            if (!data.site.loading && !data.privacyPolicy.loading && data.privacyPolicy.content === '') {
                void fetchTerms(data.privacyPolicy, (data.site.content?.privacyPolicy as string | undefined) ?? '');
            }
            return data.privacyPolicy.content;
        }),
        legalNotices: computed(() => {
            if (!data.site.loading && !data.legalNotices.loading && data.legalNotices.content === '') {
                void fetchTerms(data.legalNotices, (data.site.content?.legalNotices as string | undefined) ?? '');
            }
            return data.legalNotices.content;
        }),
        nicheMedias: computed(() => {
            if (!data.nicheMedias.loading && data.nicheMedias.content === null && data.site.content !== null) {
                return nicheStore.niches.value?.find((n) => n.internalName === data.site.content?.niche?.internalName) ?? null;
            }

            return data.nicheMedias.content;
        }),
        $reset: () => {
            resetStore(data, originalData);
        },
    };
});

const setGoogleAnalyticsCookiesData = () => {
    const my_client_id = new URL(window.location.toString()).searchParams.get('myclid') ?? localStorage.getItem(keyClientID);
    const my_session_id = new URL(window.location.toString()).searchParams.get('myssid');
    if (my_client_id) {
        localStorage.setItem(keyClientID, my_client_id);
        handleEventGtag({
            my_client_id,
            ...(my_session_id ? { my_session_id } : {}),
        });
    }
};

let retriesFetchSite = 0;
const fetchSite = async (site: StoreData<SiteQuery['Coaching']['site']>, languages: StoreData<SiteQuery['Coaching']['languages']>) => {
    site.loading = true;
    languages.loading = true;
    retriesFetchSite++;

    const host =
        new URL(window.location.toString()).searchParams.get('origin') ?? localStorage.getItem(keyOrigin) ?? (import.meta.env.VITE_FAKE_HOST as string | undefined | null) ?? window.location.origin;
    try {
        const { data, error, errors } = await (
            await getApolloClient()
        ).query<SiteQuery>({
            query: SiteDocument,
            variables: {
                host,
            },
            fetchPolicy: 'no-cache',
        });

        languages.content = data.Coaching.languages;
        if (data.Coaching.site) {
            site.content = data.Coaching.site;
            // eslint-disable-next-line require-atomic-updates
            retriesFetchSite = 0;
            if (site.content.niche) {
                handleEventGtag({ site_domain: site.content.domain, niche_name: site.content.niche.internalName });
            }
            setGoogleAnalyticsCookiesData();
            handleScriptGtm();
        } else if ((errors && errors.length >= 1) || error) {
            throw new Error('Error while fetching site');
        } else {
            site.content = null;
            await waitTime(1000);
        }
    } catch {
        site.content = null;
        if (retriesFetchSite === 3) {
            await handleError();
        } else {
            await waitTime(1000);
        }
    }

    removeParamFromUrl('origin');
    // eslint-disable-next-line require-atomic-updates
    site.loading = false;
    languages.loading = false;
};

const fetchTerms = async (terms: StoreData<string>, TOS: string) => {
    terms.loading = true;
    terms.content = DOMPurify.sanitize(await marked.parse(TOS, {}));
    terms.loading = false;
};
